<template>
  <app-module-view>
    <template slot="buttons">

    </template>
    <template slot="body">
      <section class="position-relative">
        <div>
          <div class="top-buttons">
            <div class="d-inline-block">
              <app-checkbox
                id="don_npa_autorefresh"
                v-model="autoRefresh"
                :label="$t('auto_refresh')">
              </app-checkbox>
            </div>
            <router-link
              v-if="isConfigButtonVisible"
              tag="button"
              class="btn btn btn-secondary"
              :to="{ name: 'don_npa_config' }"
            >
              <i class="fas fa-cog"></i> {{ $t("don.npa.buttons.settings") }}
            </router-link>
            <div class="d-inline-block">
              <app-button-csv-export
                v-if="isExportButtonVisible"
                :data="items"
                filename="Native perf articles"
                :btnClass="btnClass"
              ></app-button-csv-export>
            </div>
            <router-link
              v-if="isCreateButtonVisible"
              tag="button"
              class="btn btn btn-success"
              :to="{ name: 'don_npa_new', query: { originalPath: '/nativePerfArticle' } }"
            >
              <i class="fas fa-plus"></i> {{ $t("don.npa.buttons.add") }}
            </router-link>
          </div>

          <div v-if="autoRefresh">
            <app-autoloader :callback="refreshCurrentPage" :height="2" :interval="60"></app-autoloader>
          </div>

          <app-filter></app-filter>

          <div class="card">
            <div class="card-body">
              <div class="table-responsive">
                <table id="list-table" class="table table-striped">
                  <thead>
                  <tr>
                    <th>
                      {{ $t("don.npa.status") }}
                    </th>
                    <th>
                      {{ $t("don.npa.agency") }}
                    </th>
                    <th>
                      {{ $t("don.npa.client") }}
                    </th>
                    <th>
                      {{ $t("don.npa.title") }}
                    </th>
                    <th>
                      {{ $t("don.npa.interval") }}
                    </th>
                    <th class="text-right">
                      {{ $t("don.npa.days") }}
                    </th>
                    <th class="text-right">
                      {{ $t("don.npa.impressions") }}
                    </th>
                    <th class="text-right">
                      {{ $t("don.npa.visits") }}
                    </th>
                    <th class="text-right">
                      {{ $t("don.npa.clicks") }}<br>
                      ({{ $t("don.npa.total_price") }})
                    </th>
                    <th class="text-right">
                      {{ $t("don.npa.ctr") }}
                    </th>
                    <th class="text-right">
                      {{ $t("don.npa.clicks_limit") }}
                    </th>
                    <th>
                      {{ $t("don.npa.progress") }}
                    </th>
                    <th>
                      {{ $t("don.npa.bonus") }}
                    </th>
                    <th class="text-right">
                      {{ $t("don.npa.divided_by_days_shortname") }}
                      <app-tooltip :title="$t('don.npa.divided_by_days')"></app-tooltip>
                    </th>
                    <th>
                      {{ $t("don.npa.actions") }}
                    </th>
                  </tr>
                  </thead>
                  <tbody v-if="articlesLoaded && items.length > 0">
                  <tr v-for="(item, index) in items" :key="`item-${index}`">
                    <td>
                      <span v-if="item.status == 2" class="label label-success text-uppercase"><strong>{{
                          $t("don.npa.active")
                        }}</strong></span>
                      <span v-else-if="item.status == 3"
                            class="label btn-danger text-uppercase"><strong>{{ $t("don.npa.done") }}</strong></span>
                      <span v-else-if="item.status == 4"
                            class="label btn-green text-uppercase"><strong>{{ $t("don.npa.paused") }}</strong></span>
                      <span v-else class="label btn-primary text-uppercase"><strong>{{
                          $t("don.npa.inactive")
                        }}</strong></span>
                    </td>
                    <td>{{ item.agency }}</td>
                    <td>{{ item.client }}</td>
                    <td>{{ item.title }}</td>
                    <td>{{ item.publishedSince | prettyDate }} - {{ item.publishedUntil | prettyDate }}</td>
                    <td class="text-right">{{ activeDays[index] | formatNumber }}</td>
                    <td class="text-right">{{ item.views }}</td>
                    <td class="text-right">{{ item.nativePerfViews }}</td>
                    <td class="text-right">
                      {{ item.clicks }}<br>
                      {{ (item.clicks * item.clickPrice) | formatNumber }} €
                    </td>
                    <td class="text-right">{{ ctr[index] | formatNumber }}</td>
                    <td class="text-right">{{ item.clicksLimit }}</td>
                    <td>
                      <div class="progress">
                        <div class="progress-bar bg-success"
                             :style="'width: ' + clickProgress[index] + '%; height:20px;'"
                        >
                          {{ clickProgress[index] | formatNumber }} %
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="progress">
                        <div class="progress-bar btn-green"
                             :style="'width: ' + bonusProgress[index] + '%; height:20px;'">
                          {{ bonusProgress[index] | formatNumber }} %
                        </div>
                      </div>
                    </td>
                    <td class="text-right">
                      <i v-if="item.dividedByDays" class="fa fa-check"></i>
                    </td>
                    <td>
                      <router-link
                        tag="button"
                        class="btn btn-default btn-sm"
                        :to="{ name: 'don_npa_detail', params: { id: item.id }, query: { originalPath: '/nativePerfArticle' } }"
                      >
                        <i class="fa fa-info"></i>
                      </router-link>
                      <router-link
                        v-if="item.status !== 3 && isEditButtonVisible"
                        tag="button"
                        class="btn btn-default btn-sm"
                        :to="{ name: 'don_npa_edit', params: { id: item.id }, query: { originalPath: '/nativePerfArticle' } }"
                      >
                        <i class="fa fa-edit"></i>
                      </router-link>
                      <router-link
                        v-if="item.status === 3 && item.duplicate === false && isDuplicateButtonVisible"
                        tag="button"
                        class="btn btn-default btn-sm"
                        :to="{ name: 'don_npa_duplicate', params: { id: item.id }, query: { originalPath: '/nativePerfArticle' } }"
                      >
                        <i class="fa fa-redo"></i>
                      </router-link>
                    </td>
                  </tr>
                  </tbody>
                  <tbody v-if="!articlesLoaded">
                  <tr>
                    <td colspan="15" class="loader-td">
                      <app-preloader></app-preloader>
                    </td>
                  </tr>
                  </tbody>
                  <tbody v-if="articlesLoaded && items.length < 1">
                  <tr>
                    <td colspan="15">
                      {{ $t("don.npa.total_items_count") }}: {{ pagination.totalCount }}
                    </td>
                  </tr>
                  </tbody>
                </table>
                <div v-if="articlesLoaded" class="row mx-0">
                  <div class="col-12">
                    <p class="m-t-20">
                      {{ $t("don.npa.total_items_count") }}: {{ pagination.totalCount }}<br>
                      {{ $t("don.npa.total_pages_count") }}: {{ totalPages }}
                    </p>
                  </div>
                  <div class="col-12 d-flex justify-content-center">
                    <button v-if="currentPage > 1" @click="prevPage" type="button"
                            class="btn waves-effect waves-light btn-secondary">{{ $t("page_prev") }}
                    </button>
                    &nbsp;
                    <button v-if="currentPage < totalPages" @click="nextPage" type="button"
                            class="btn waves-effect waves-light btn-secondary">{{ $t("page_next") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </template>
  </app-module-view>
</template>
<script>
import ModuleView from '../ModuleView'
import { mapGetters, mapMutations, mapState } from 'vuex'
import Checkbox from '../form/Checkbox'
import Preloader from '../preloader/Preloader'
import Autoloader from '../Autoloader'
import DonService from '../../services/don/DonService'
import config from '../../config'
import Tooltip from '../tooltip/Tooltip'
import Filter from './NativePerfArticleFilter'
import ButtonCsvExport from '@/components/shared/ButtonCsvExport'
import PermissionService from '@/services/PermissionService'

export default {
  name: 'NativePerfArticleList',
  data () {
    return {
      autoRefresh: false,
      btnClass: 'btn',
      requiredPermissions: PermissionService.REQUIRED_PERMISSIONS.SPONSORED_ARTICLES_CAMPAIGN_MANAGEMENT_PERMISSIONS
    }
  },

  computed: {
    ...mapState([
      'callingAPI'
    ]),
    ...mapGetters({
      items: 'nativePerfArticle/list',
      pagination: 'nativePerfArticle/pagination',
      articlesLoaded: 'nativePerfArticle/isArticlesLoaded'
    }),
    isConfigButtonVisible () {
      return this.hasPermission(this.requiredPermissions.configButton)
    },
    isExportButtonVisible () {
      return this.hasPermission(this.requiredPermissions.exportButton)
    },
    isCreateButtonVisible () {
      return this.hasPermission(this.requiredPermissions.createButton)
    },
    isEditButtonVisible () {
      return this.hasPermission(this.requiredPermissions.editButton)
    },
    isDuplicateButtonVisible () {
      return this.hasPermission(this.requiredPermissions.duplicateButton)
    },
    activeDays: function () {
      return this.items.map(function (item) {
        return DonService.daysBetween(item.publishedSince, item.publishedUntil)
      })
    },

    ctr: function () {
      return this.items.map(function (item) {
        if (item.clicks > 0 && item.nativePerfViews > 0) {
          return Number.parseFloat(item.clicks / item.nativePerfViews * 100).toFixed(2)
        } else {
          return 0
        }
      })
    },

    clickProgress: function () {
      return this.items.map(function (item) {
        if (item.clicksLimit > 0) {
          const value = ((item.clicks * 100) / item.clicksLimit).toFixed(1)
          return (value > 100) ? 100 : value
        } else {
          return 0
        }
      })
    },

    bonusProgress: function () {
      return this.items.map(function (item) {
        if (item.bonusClicksLimit > 0) {
          const value = ((item.bonusClicks * 100) / item.bonusClicksLimit).toFixed(1)
          return (value > 100) ? 100 : value
        } else {
          return 0
        }
      })
    },

    totalPages: function () {
      return Math.ceil(this.pagination.totalCount / config.defaults.list.limit)
    },

    currentPage: {
      get () {
        return this.pagination.currentPage
      },
      set (value) {
        this.setCurrentPage(value)
      }
    }
  },

  methods: {
    ...mapMutations({
      resetPagination: 'nativePerfArticle/resetPagination',
      resetList: 'nativePerfArticle/resetList',
      setCurrentPage: 'nativePerfArticle/setCurrentPage'
    }),

    hasPermission (permission) {
      return this.$store.getters['user/hasPermission'](permission)
    },

    refreshCurrentPage () {
      this.refreshArticles()
    },

    prevPage () {
      this.currentPage = this.currentPage - 1
      if (this.pagination.firstItemId === null) {
        this.currentPage = 1
        this.resetPagination()
        this.getArticles('init')
      } else {
        this.resetList()
        this.getArticles('prev')
      }
    },

    nextPage () {
      this.currentPage = this.currentPage + 1
      this.resetList()
      this.getArticles('next')
    },

    refreshArticles () {
      this.$store.commit('nativePerfArticle/setArticlesLoaded', false)

      this.$store.dispatch('nativePerfArticle/refresh')
        .then(() => {
          this.$store.commit('nativePerfArticle/setArticlesLoaded', true)
        })
        .catch((error) => {
          console.log(error)
          this.$store.commit('nativePerfArticle/setArticlesLoaded', true)
        })
    },

    getArticles (type = 'init') {
      this.$store.commit('nativePerfArticle/setArticlesLoaded', false)

      this.$store.dispatch('nativePerfArticle/fetch', type)
        .then(() => {
          this.$store.commit('nativePerfArticle/setArticlesLoaded', true)
        })
        .catch((error) => {
          console.log(error)
          this.$store.commit('nativePerfArticle/setArticlesLoaded', true)
        })
    }
  },
  components: {
    appModuleView: ModuleView,
    appPreloader: Preloader,
    appAutoloader: Autoloader,
    appCheckbox: Checkbox,
    appTooltip: Tooltip,
    appFilter: Filter,
    appButtonCsvExport: ButtonCsvExport
  },
  mounted () {
    this.currentPage = 1
    this.resetPagination()
    this.getArticles('init')
  }
}
</script>

<style lang="scss" scoped>
.table-responsive {
  th, td {
    font-size: 14px;

    &:nth-child(2) {
      width: 9%;
    }

    &:nth-child(3) {
      width: 9%;
    }

    &:nth-child(4) {
      width: 17%;
    }
  }
}

.position-relative {
  position: relative;
}

.loader-td {
  position: relative;
  height: 80px;
  background-color: white;
  overflow: hidden;
}

.top-buttons {
  position: absolute;
  top: -60px;
  right: 0;
  z-index: 5;
}

.progress-bar {
  color: #212529;
}

.table-striped tbody tr:nth-of-type(odd) {
  .progress {
    background-color: #fff;
  }
}

#list-table {
  td:last-of-type {
    min-width: 80px;
  }
}

.current-page {
  display: inline-block;
  padding: 5px 20px;
}

.card-no-border .card {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
</style>
