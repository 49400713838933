<template>
  <section>
    <div class="card">
      <div class="card-body">
        <form @submit.prevent="filterArticles">
          <div class="row">
            <div class="col-lg-1 col-md-6">
              <app-select
                v-model="filter.status"
                :options="statuses"
                :label="$t('don.npa.status')"
                id="filter_status"
                disable-form-group
              >
              </app-select>
            </div>
            <div class="col-lg-3 col-md-6">
              <app-input v-model.trim="filter.agency"
                         id="filter_agency"
                         :label="$t('don.npa.agency')"
                         disable-form-group
              >
              </app-input>
            </div>
            <div class="col-lg-3 col-md-6">
              <app-input v-model.trim="filter.client"
                         id="filter_client"
                         :label="$t('don.npa.client')"
                         disable-form-group
              >
              </app-input>
            </div>
            <div class="col-lg-3 col-md-6">
              <app-input v-model.trim="filter.title"
                         id="filter_title"
                         :label="$t('don.npa.title')"
                         disable-form-group
              >
              </app-input>
            </div>
            <div class="col-lg-2">
              <button
                type="button"
                class="btn btn-success pull-right m-t-30"
                data-test="search"
                @click="filterArticles"
              >
                <i class="fa fa-search"></i> {{ $t('buttons.search') }}
              </button>
              <button
                type="button"
                class="btn btn-secondary m-t-30"
                data-test="reset_filter"
                @click="resetFilter"
              >
                {{ $t('buttons.reset_filter') }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>
<script>
import Input from '../form/inputs/Input'
import Select from '../form/select/Select'
import NativePerfArticleFilter from '../../model/NativePerfArticleFilter'

export default {
  name: 'NativePerfArticleFilter',
  data () {
    return {
      isLoading: false,
      filter: this._.cloneDeep(NativePerfArticleFilter)
    }
  },
  computed: {
    statuses () {
      return [
        {
          id: 1,
          title: this.$t('don.npa.statuses.inactive')
        },
        {
          id: 2,
          title: this.$t('don.npa.statuses.active')
        },
        {
          id: 3,
          title: this.$t('don.npa.statuses.done')
        },
        {
          id: 4,
          title: this.$t('don.npa.statuses.paused')
        }
      ]
    }
  },
  components: {
    appInput: Input,
    appSelect: Select
  },
  methods: {
    filterArticles () {
      this.$store.commit('nativePerfArticle/setArticlesLoaded', false)

      this.$store.commit('nativePerfArticle/setFilter', this.filter)
      this.$store.commit('nativePerfArticle/resetPagination')

      this.$store.dispatch('nativePerfArticle/fetch')
        .then(() => {
          this.$store.commit('nativePerfArticle/setArticlesLoaded', true)
        })
    },
    resetFilter () {
      this.$store.commit('nativePerfArticle/setArticlesLoaded', false)

      this.filter = this._.cloneDeep(NativePerfArticleFilter)
      this.$store.commit('nativePerfArticle/resetFilter')
      this.$store.commit('nativePerfArticle/resetPagination')

      this.$store.dispatch('nativePerfArticle/fetch')
        .then(() => {
          this.$store.commit('nativePerfArticle/setArticlesLoaded', true)
        })
    }
  },
  beforeMount () {
    this.filter = this.$store.getters['nativePerfArticle/filter']
  }
}
</script>

<style lang="scss">

</style>
