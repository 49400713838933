const moment = require('moment')

const daysBetween = function (rawDate1, rawDate2) {
  const firstDate = moment(rawDate1).toDate()
  const secondDate = moment(rawDate2).toDate()
  const oneDay = 24 * 60 * 60 * 1000
  const days = Math.abs((firstDate.getTime() - secondDate.getTime()) / (oneDay))
  return Math.round(days * 10) / 10
}

export default {
  daysBetween
}
